import { useOutletContext } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser'
import par_mums from '../assets/par mums.jpg'

export default function AboutUs({lang}) {


  
  const [dictionary,setDictionary] = useOutletContext();

    
 
    
    
      


      return (
        <main>
          <Helmet>
                <meta charSet="utf-8" />
                <title>{dictionary.about_us}</title>
                <meta name="description" content="Product" />
            </Helmet>
          <div className="container">
        
          </div>
          <div className='mx-2 mx-md-4 my-5 row'>
            <div className='col-md-3 order-md-0 order-1'>
            <div className="sb-contacts mb-4" style={{border:"solid 1px #787878"}}>
    <div className="sb-contact-map" style={{textAlign:"left"}}>
        <iframe style={{border:0}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2176.0297184060078!2d24.11638361554155!3d56.948292106622944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eecfd37a9d67c7%3A0xc5316fead4c015d9!2zUmFpxYZhIGJ1bHbEgXJpcyAzMSwgQ2VudHJhIHJham9ucywgUsSrZ2EsIExWLTEwNTA!5e0!3m2!1slv!2slv!4v1580536396863!5m2!1slv!2slv" width="400" height="300" frameBorder="0" className='w-100' allowFullScreen="allowfullscreen"></iframe>
    </div>
    <h4 className="sb-contact-map pt-3 px-2" style={{textAlign:"center"}}>
        Raiņa bulvāris 31, Rīga
    </h4>
    <div className="sb-contact-rest" style={{color:"#787878"}}>
        <div className="p-3" style={{textAlign:"left"}}>
            {parse(dictionary.working_time)}
        </div>
        <div className="sb-contact-bottom pb-4">
            
            <div style={{textAlign:"center"}}>
                <a href="mailto:info@salonsmuzika.lv" style={{color:"#4e8735"}}><strong>info@carlgustafbags.lv</strong></a>
            </div>
        </div>
    </div>
</div>
            </div>
            <div className='col-md-9'>
              <div className='mb-4' style={{textAlign:"justify",fontSize:15}}>
              <div style={{fontWeight:"bold",color:"#4e8735",fontSize:20}}>{dictionary.about_us}</div>
              <img className='my-4' src={par_mums} style={{maxHeight:600}}></img>
            {parse(dictionary.about_us_description)}
            
              </div>
            










            </div>
            
          
          </div>
         
        </main>
      )
    }