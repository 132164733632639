
import latvia from '../../assets/latvia.png'
import uk from '../../assets/uk.png'
import estonia from '../../assets/estonia.png'
import lithuania from '../../assets/lithuania.png'
import sweden from '../../assets/sweden.png'
import finland from '../../assets/finland.png'
import { useNavigate } from 'react-router-dom';
export const LangSwitch = ({supportedLang,currentLang,width})=>{
    let currentFlag = latvia
    
    switch(currentLang){
        case "lv":{
            currentFlag=latvia
            break
        }
        case "en":{
            currentFlag=uk
            break
        }
        case "et":{
            currentFlag=estonia
            break
        }
        case "lt":{
            currentFlag=lithuania
            break
        }
        case "sv":{
            currentFlag=sweden
            break
        }
        case "fi":{
            currentFlag=finland
            break
        }
    }
    const navigate = useNavigate();
    const pathArr = window.location.pathname.split("/").filter((str) => str !== '')
    const changeLang = (newLang,currentPathArr)=>{
        if(supportedLang.includes(newLang)){
            if(supportedLang.includes(currentPathArr[0])){
                currentPathArr[0] = newLang
                navigate("/"+currentPathArr.join("/").toString(),{replace:true})
            }else{
                currentPathArr.unshift(newLang)
                navigate(currentPathArr.join("/").toString(),{replace:true})
            }
        }
    }
    return <>
    <div className="languages">
  <ul className="list d-flex flex-wrap mb-0 px-0" align="center" style={{listStyle:'none',width:width}}>
    <li><a className="dropdown-item px-1 pb-1" href="#"><div className='d-flex' onClick={()=>{changeLang("lv",pathArr)}}><img src={latvia} width={40} style={{border:"1px solid black"}}></img></div></a></li>
    <li><a className="dropdown-item px-1 pb-1" href="#"><div className='d-flex' onClick={()=>{changeLang("en",pathArr)}}><img src={uk} width={40} style={{border:"1px solid black"}}></img></div></a></li>
    <li><a className="dropdown-item px-1 pb-1" href="#"><div className='d-flex' onClick={()=>{changeLang("et",pathArr)}}><img src={estonia} width={40} style={{border:"1px solid black"}}></img></div></a></li>
    <li><a className="dropdown-item px-1" href="#"><div className='d-flex' onClick={()=>{changeLang("lt",pathArr)}}><img src={lithuania} width={40} style={{border:"1px solid black"}}></img></div></a></li>
    <li><a className="dropdown-item px-1" href="#"><div className='d-flex' onClick={()=>{changeLang("sv",pathArr)}}><img src={sweden} width={40} style={{border:"1px solid black"}}></img></div></a></li>
    <li><a className="dropdown-item px-1" href="#"><div className='d-flex' onClick={()=>{changeLang("fi",pathArr)}}><img src={finland} width={40} style={{border:"1px solid black"}}></img></div></a></li>
  </ul>
</div>


    </>
    
}
{/* <div><img src={latvia} onClick={()=>{
        changeLang("lv",pathArr)
        
    }} width={40} className='mx-2'></img></div>
    <div><img src={uk} width={40} onClick={()=>{
        changeLang("en",pathArr)
    }} className='mx-2'></img></div> */}