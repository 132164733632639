

import { useParams } from 'react-router';

import { useOutletContext } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


export default function Error({lang}) {
  
  const params= useParams()
  
  const parser = new DOMParser();

  
  const [dictionary,setDictionary] = useOutletContext();
  
    
     
      

      return (
        <main>
          <Helmet htmlAttributes={{ lang : lang }}>
          <meta charSet="utf-8" />
          <title>{"error"}</title>
          <meta name="description" content={"error"}/>
          </Helmet>
          <div className='mt-5' style={{fontSize:40,fontWeight:500,color:"#4e8735"}}>404</div>
        </main>
      )
    }