import React, { Component, useEffect } from "react";
import Layout from "./components/Layout";
import Main from "./Main";
import Error from "./Error";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";
import "./App.css";
import Product from "./Product";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AboutUs from "./AboutUs";
import Contacts from "./Contacts";
import Payment from "./Payment";

class App extends Component {
    
    render() {
        return (
          <HelmetProvider>
            <div className="App">
              
            <Router basename="/">
            
                <Helmet>
                <meta charSet="utf-8" />
                <title>CG84</title>
                <meta name="description" content="Somas cg84" />
                
              </Helmet>
                    <Routes>
                      <Route exact path="/" element={<Layout lang="lv"></Layout>}>
                        <Route index element={<Main lang="lv"/>}></Route>
                        <Route path="product/:product" element={<Product lang="lv"/>}></Route>
                        <Route path="contacts" element={<Contacts lang="lv"></Contacts>}></Route>
                        <Route path="about-us" element={<AboutUs lang="lv"></AboutUs>}></Route>
                        <Route path="payment" element={<Payment lang="lv"></Payment>}></Route>
                        <Route path="*" element={<Error lang="lv"></Error>}></Route>
                      </Route>
                      <Route exact path="/lv" element={<Layout lang="lv"></Layout>}>
                        <Route index element={<Main lang="lv"/>}></Route>
                        <Route path="product/:product" element={<Product lang="lv"/>}></Route>
                        <Route path="contacts" element={<Contacts lang="lv"></Contacts>}></Route>
                        <Route path="about-us" element={<AboutUs lang="lv"></AboutUs>}></Route>
                        <Route path="payment" element={<Payment lang="lv"></Payment>}></Route>
                        <Route path="*" element={<Error lang="lv"></Error>}></Route>
                      </Route>
                      <Route exact path="/en" element={<Layout lang="en"></Layout>}>
                        <Route index element={<Main lang="en"/>}></Route>
                        <Route path="product/:product" element={<Product lang="en"/>}></Route>
                        <Route path="contacts" element={<Contacts lang="en"></Contacts>}></Route>
                        <Route path="about-us" element={<AboutUs lang="en"></AboutUs>}></Route>
                        <Route path="payment" element={<Payment lang="en"></Payment>}></Route>
                        <Route path="*" element={<Error lang="en"></Error>}></Route>
                      </Route>
                      <Route  exact path="/et" element={<Layout lang="et"></Layout>}>
                        <Route index element={<Main lang="et"/>}></Route>
                        <Route path="product/:product" element={<Product lang="et"/>}></Route>
                        <Route path="contacts" element={<Contacts lang="et"></Contacts>}></Route>
                        <Route path="about-us" element={<AboutUs lang="et"></AboutUs>}></Route>
                        <Route path="payment" element={<Payment lang="et"></Payment>}></Route>
                        <Route path="*" element={<Error lang="et"></Error>}></Route>
                      </Route>
                      <Route  exact path="/lt" element={<Layout lang="lt"></Layout>}>
                        <Route index element={<Main lang="lt"/>}></Route>
                        <Route path="product/:product" element={<Product lang="lt"/>}></Route>
                        <Route path="contacts" element={<Contacts lang="lt"></Contacts>}></Route>
                        <Route path="about-us" element={<AboutUs lang="lt"></AboutUs>}></Route>
                        <Route path="payment" element={<Payment lang="lt"></Payment>}></Route>
                        <Route path="*" element={<Error lang="lt"></Error>}></Route>
                      </Route>
                      <Route  exact path="/sv" element={<Layout lang="sv"></Layout>}>
                        <Route index element={<Main lang="sv"/>}></Route>
                        <Route path="product/:product" element={<Product lang="sv"/>}></Route>
                        <Route path="contacts" element={<Contacts lang="sv"></Contacts>}></Route>
                        <Route path="about-us" element={<AboutUs lang="sv"></AboutUs>}></Route>
                        <Route path="payment" element={<Payment lang="sv"></Payment>}></Route>
                        <Route path="*" element={<Error lang="sv"></Error>}></Route>
                      </Route>

                      <Route  exact path="/fi" element={<Layout lang="fi"></Layout>}>
                        <Route index element={<Main lang="fi"/>}></Route>
                        <Route path="product/:product" element={<Product lang="fi"/>}></Route>
                        <Route path="contacts" element={<Contacts lang="fi"></Contacts>}></Route>
                        <Route path="about-us" element={<AboutUs lang="fi"></AboutUs>}></Route>
                        <Route path="payment" element={<Payment lang="fi"></Payment>}></Route>
                        <Route path="*" element={<Error lang="fi"></Error>}></Route>
                      </Route>
                      
                    </Routes>
                    
            </Router>
            </div>
          </HelmetProvider>
            
        );
    }
}
 
export default App;