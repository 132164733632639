
import { Outlet } from "react-router-dom"
import fons from '../../assets/fons.png'
import logo from '../../assets/logo cg84.png'
import { LangSwitch } from "./langSwitch"
import lvLang from '../../languages/lv.json'
import { useState,useEffect } from "react"
import "../../Layout.css"
import parse from "html-react-parser"

const Layout = ({lang}) => {





  let supportedLang = ["lv","en","et","lt","sv","fi"]
  const [dictionary,setDictionary] = useState(lvLang);
  useEffect(()=>{
    let getLang = async (lang)=>{
      let result = await import(`../../languages/${lang}.json`)
      return result
    }
   
    getLang(lang).then((res)=>{setDictionary(res.default)})
  })
    return (
    
    <div lang="lang">
      <div>
        <div style={{
      backgroundImage: `url(${fons})`,
      width: '100%',
      height: '100%',
      backgroundColor:"rgba(0, 0, 0, 0.1)"
    }}>
<div className='sticky-top' style={{backgroundColor:"#f7f7f7",boxShadow:"0px 5px 5px #6e6d6d"}}>
        <div className='container'>
        <nav className="px-2 navbar navbar-expand-lg justify-content-start" style={{paddingTop:4,paddingBottom:4}} >
  <a className="navbar-brand pb-0 pt-0" href={"/"+lang} style={{color:"#4e8735",fontWeight:"1500",textShadow: "1px 0px 0px #4e8735",
    paddingRight: "1px",
    letterSpacing: "1px"}}><img src={logo} width={90}></img></a>
  
  <button className="ms-auto navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sub-menu" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div className="navbar-nav  align-items-baseline">
      
      <a className="nav-item nav-link active "  href={"/"+lang+"/about-us"} style={{fontWeight:"600",fontSize:17,textDecoration:"none",color:"#4e8735"}}> {dictionary.about_us}</a>
      <a className="nav-item nav-link " href={"/"+lang+"/contacts"} style={{color:"#4e8735",fontWeight:"600",fontSize:17}}>{dictionary.contacts}</a>
      <a className="nav-item nav-link " href={"/"+lang+"/payment"} style={{color:"#4e8735",fontWeight:"600",fontSize:17}}>{dictionary.payment}</a>
      
      
    </div>
    <div className='ms-auto d-flex align-items-center'>
      <div className="me-1 d-flex">
      <a style={{textDecoration:"none"}} href="mailto:info@carlgustafbags.com"><div className="d-flex align-items-center"><i className="bi bi-envelope-at" style={{color:"#4e8735",fontSize:"30px"}}></i> <div className="ms-2 me-5" style={{color:"#4e8735",fontSize:17,fontWeight:500}}>info@carlgustafbags.com</div></div></a>
      {/*<div className="d-flex align-items-center"><i className="bi bi-phone" style={{color:"#4e8735",fontSize:"30px"}}></i> <div className="ms-2 me-2" style={{color:"#4e8735",fontSize:17,fontWeight:500}}>+371 29507001</div></div>*/}
      
        
      </div>
    
    <LangSwitch supportedLang={supportedLang} currentLang={lang} width={180}></LangSwitch>
    </div>
    
  </div>
</nav>
        </div>
        
        <div className="w-100 collapse" id="sub-menu">
          <div align="center" className="py-3"><LangSwitch supportedLang={supportedLang}  currentLang={lang} width={290} className=""></LangSwitch></div>
          <div style={{color:"#4e8735",fontWeight:"600"}} className="py-3"><a href={"/"+lang+"/about-us"} style={{textDecoration:"none",color:"#4e8735"}}>{dictionary.about_us}</a></div>
          <div style={{color:"#4e8735",fontWeight:"600"}} className="py-3"><a href={"/"+lang+"/contacts"} style={{textDecoration:"none",color:"#4e8735"}}>{dictionary.contacts}</a></div>
          <div style={{color:"#4e8735",fontWeight:"600"}} className="py-3"><a href={"/"+lang+"/payment"} style={{textDecoration:"none",color:"#4e8735"}}>{dictionary.payment}</a></div>
        </div>
        </div>
        
        
        <div className='container fill min-vh-100 p-0' style={{backgroundColor:"white",display:"flex",flexDirection:"column",height:'100%',minHeight:"100%"}}>
        
            <div className=''>
            <Outlet context={[dictionary,setDictionary]}/>
            </div>
        </div>
        <div style={{backgroundColor:"#4e8735"}} className="pt-4">
          <div className="container">
            {parse(dictionary.footer)}
          
          </div>
          
        </div>
        <div className="py-4" style={{backgroundColor:"#2c6b10"}}>

        </div>
    </div>
        
      </div>
    </div>
  )
}
export default Layout;