
import styles from "../../Product.module.css"


import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './style-swiper.css';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Controller } from 'swiper/modules';



const Carousel = ({images,height,ClassParams,imageParams,bttnColor,carousel,modal,carouselId,bootstrap,initialSlide,setModalLoaded,lazy,initFunction})=>{
  
    if(!bttnColor){
      bttnColor=""
    }
    let modalEl

    if(carouselId=="#CarouselInModal"){
      
    }
    if(modal){
      

      

      modalEl = document.querySelector(modal)
      if(modalEl){
        
        modalEl.addEventListener('hide.bs.modal', event => {
          return event
        })
      }
      
    }
   
   


    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    




    return (carousel=="bootstrap"?<div className={`  mt-5  h-auto  ${ClassParams}`} style={{width:500}}>
        
    <div id="carouselExampleCaptions" className="carousel slide " data-ride="carousel">
<div className="carousel-indicators">
  {images.map((image,index)=>{
    
    if(index==0){
      return (<button type="button" data-bs-target="#carouselExampleCaptions" key={"button-0"} data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>)
    }else{
      return (<button type="button" data-bs-target="#carouselExampleCaptions" key={"button-"+index} data-bs-slide-to={index} aria-label={"Slide "+ index}></button>)
    }
   
  })}
  
</div>
<div className="carousel-inner">
    {images.map((imgSrc,index)=>{
        if(index==0){
          
    return (<div className="carousel-item active" key={imgSrc}>
    <img src={imgSrc} className={`d-block  ${imageParams}`} style={{maxHeight:height,minHeight:height,objectFit:"scale-down"}} width={600} unoptimized="true" ></img>
    
  </div>)
        }else{
            return (
        <div className="carousel-item" key={imgSrc}>
        <img src={imgSrc} className={`d-block  ${imageParams}`} style={{maxHeight:height,minHeight:height,objectFit:"scale-down"}} width={600} unoptimized="true" ></img>
        
     </div>
            )
        }
    })}
  
  
  
</div>

<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
  <span className={`carousel-control-prev-icon ${styles["carousel-control-prev-icon"+bttnColor]}`} aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className={`carousel-control-next `} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
  <span className={` ${styles["carousel-control-next-icon"+bttnColor]} carousel-control-next-icon`}  aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
</div>
    </div>
    
    
    
    :
    
    <div  className={`carousel-wrapper ${carouselId=="#CarouselInModal"?"position-relative h-100 CarouselInModal":""} ${ClassParams}`}>
      
      


  

 
      <style>
        {`
        .swiper-button-prev {
          color: green; /* Change the color to your preferred value */
          
        }

        .swiper-button-prev.swiper-button-disabled {
          !pointer-events: auto;
        }
        
        /* Set the color for the next button */
        .swiper-button-next {
          color: green; /* Change the color to your preferred value */
        }
        
        @media (max-width: 576px) {

          .carousel-wrapper {
            height:calc(100% - 60px)
          }

          ${
            carouselId=="#CarouselInModal"?`
            .CarouselInModal.thumbs-wrapper{
              position:absolute;
              top: calc(100% - 170px);
            }
            `:""
          }
        }
        
        `}
      </style>
      <Swiper
        loop={false}
        spaceBetween={10}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        modules={[FreeMode, Navigation, Thumbs, Controller]}
        onSwiper={(swiper)=>initFunction(swiper,carouselId)}
        initialSlide={initialSlide}
        className="mySwiper2"
        id={carouselId}
      >
        {images.map((imgSrc,index)=>{

        
        let imageLight = imgSrc.split("/")
        imageLight[0] = "/light"
          
        imageLight = imageLight.join("/").toString()
        
        const temp = imgSrc.split("/")
        temp[0] = "/regular"
          
        imgSrc = temp.join("/").toString()
        
        return (
      
      <SwiperSlide key={imgSrc}>
        <img  onClick={()=>{
        
        if(modal){
          
          const carouselModal = new bootstrap.Modal(modalEl)
        
          carouselModal.toggle();
          
          

        }
      }} src={(carouselId=="#Carousel")?imageLight:lazy?imageLight:imgSrc} srcSet={!(carouselId=="#Carousel")?imgSrc:imageLight} loading={lazy?"lazy":"eager"} key={imgSrc} className={`d-block h-100 ${imageParams}`} style={{maxHeight:height,objectFit:"scale-down"}} width={600} unoptimized="true" >
        </img>
        <div className={`${lazy?"swiper-lazy-preloader":""}`}></div>
        </SwiperSlide>
     
        )
      
      })}
      <div className="swiper-button-prev" style={{pointerEvents:"auto"}}></div>
      <div className={`swiper-button-next`} style={{pointerEvents:"auto"}}></div>
      </Swiper>
      {images.length>1?<div className={`w-100 thumbs-wrapper`}><Swiper
       
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        loop={false}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper mt-auto"
      >
        {images.map((imgSrc,index)=>{
        
          const temp = imgSrc.split("/")
          temp[0] = "/light"
          imgSrc = temp.join("/").toString()
       
        
        return (
      <SwiperSlide key={imgSrc}><img src={imgSrc} key={imgSrc} className={`d-block`} style={{objectFit:"scale-down",width:60}}   ></img></SwiperSlide>
      
        )
      
      })}
      </Swiper></div>:<></>}
    
  


    </div>
    
    
    
    
    )
};


export default Carousel
/*

*/