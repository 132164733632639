import data from '../products.json'

import { useParams } from 'react-router';
import { useSwiper } from 'swiper/react';
import  Carousel  from './components/Carousel';
import { useEffect,useState,useRef, lazy, Suspense } from 'react';
import { useOutletContext } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import parse from "html-react-parser"
import { ProductItem } from './components/ProductItem';


export default function Product({lang}) {
  
  const params= useParams()
  
  const parser = new DOMParser();

  
  const [dictionary,setDictionary] = useOutletContext();
  
    
      let category = {}
      const prodData = data.map((cat)=>{
        
        return cat.product.find((prod)=>{
            
            if(prod.seoParam==params.product){

              category = cat
                return prod
            }
        })
      }).filter(el=>{
        if(el!=undefined){
            return el
        }
      })[0]
      

      let html;
      let productName;
      if(prodData?.seoParam){
        html =parser.parseFromString(prodData.descriptionLV, 'text/html')
        productName = lang=="lv"?prodData.nameLV:lang=="et"?prodData.nameET:lang=="lt"?prodData.nameLT:lang=="sv"?prodData.nameSV:lang=="fi"?prodData.nameFI:prodData.name
      }
      
    const bootstrap = require('bootstrap')
    

  
    const LazyCarouselModal = lazy(() => import('./components/Carousel'));

    const carouselRef = useRef()
    const carouselModalRef = useRef()
    const wasRefSet = useRef(false)
    let modalCarousel;
      let lastIndex = null
    let indexForBoth = 0
      let modal
    useEffect(()=>{

      
  

      if(modalCarousel){
        
        
      }
    },[carouselModalRef.current])
    const initSwiper = (swiper,id)=>{
      
      if(id=="#CarouselInModal"){
        console.log("seting carousel in modal")
        carouselModalRef.current = swiper
        carouselModalRef.current.slideTo(indexForBoth,300)
        // carouselRef.current.on("slideChange",()=>{
        //   carouselModalRef.current.slideTo(indexForBoth,300)
        // })
        
      }else{
        carouselRef.current = swiper
      }
      const carousel = carouselRef.current
      modalCarousel = carouselModalRef.current
      


      

      if(modalCarousel){
        modalCarousel.on('transitionEnd',()=>{
          if(modalCarousel.realIndex!=lastIndex){
            if(modalCarousel.realIndex==0&&modalCarousel.activeIndex==modalCarousel.slides.length-1){
              
              
            
            }
            
            console.log("modal")
              indexForBoth = modalCarousel.realIndex 
              carouselRef.current.slideTo(indexForBoth,300)
              
              lastIndex=carousel.realIndex
          }
          
          

        })
      }

      if(carousel&&modalCarousel&&wasRefSet.current==false){
        wasRefSet.current=true
        
        
  
        carousel.on('transitionEnd',()=>{
          if(carousel.realIndex!=lastIndex){
            if(carousel.realIndex==0&&carousel.activeIndex==carousel.slides.length-1){
              
            
            }
              indexForBoth = carousel.realIndex 
              
              
              carouselModalRef.current.slideTo(indexForBoth,300)
              lastIndex=carousel.realIndex
          }
          
          

         
        })
      }
      
    }



    useEffect(()=>{
      
    },[carouselRef,carouselModalRef])
      return (
        <main>{prodData?.seoParam?<div><Helmet htmlAttributes={{ lang : lang }}>
          <meta charSet="utf-8" />
          <title>{productName}</title>
          <meta name="description" content={prodData["metaDescription"+lang.toUpperCase()]}/>
      </Helmet>
    <div className="container">
    
    {/* Modal !!!!*/}


<style>{`

.modal-content{
  min-height:820px
}

@media (max-width: 1200px) {
  .modal {
    background-color: white;
    height:100%
  }
  .modal-content{
    border:0px;
    min-height:820px
  }

  @media (max-width: 576px) {
    .modal-dialog {
      height:100%;
      margin:0px
    }
    .modal-body {
      
      
    }
    .modal-content{
      min-height:600px
    }
    .modal-dialog{
      
    }

    
}

`}</style>

<div className="modal fade" id="CarouselModal"   tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog w-100  mx-auto" style={{"--bs-modal-width":"100%",maxWidth:1200}}>
    <div className="modal-content h-100" style={{"--bs-modal-width":"100%"}}>
    
      <div className="modal-body">
      
      <div className="modal-header p-0" style={{border:"0px"}}>
        
        <div type="button" onClick={()=>{
        const modalElement = document.getElementById('CarouselModal');
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal.hide();
        }} aria-label="Close" className='ms-auto'><i className="bi bi-x-lg" style={{fontSize:20}}></i></div>
      </div>
      {true?
      <Suspense fallback={<div>Loading...</div>}>
  {(prodData.extraImages?
    <LazyCarouselModal initFunction={initSwiper} carouselId="#CarouselInModal"  lazy={true}   bootstrap={bootstrap} initialSlide={1}  height={650}  ClassParams={"w-100"} className="col-6" imageParams={"w-100"}  images={[prodData.primaryImage,...prodData.extraImages]}></LazyCarouselModal>:
    <LazyCarouselModal initFunction={initSwiper} carouselId="#CarouselInModal"  lazy={true}   bootstrap={bootstrap} initialSlide={1}  height={650}  ClassParams={"w-100"} className="col-6"  imageParams={"w-100"} images={[prodData.primaryImage]}></LazyCarouselModal>
  )}
      </Suspense>
    
        :<></>
  }
      </div>
      
    </div>
  </div>
</div>


        



    <div className="row mx-md-5 mb-5" >
    <div className="col-xl-6">
    {(prodData.extraImages?
    <Carousel initFunction={initSwiper} id="Carousel" carouselId="#Carousel"    initialSlide={0} bootstrap={bootstrap} height={600} modal={"#CarouselModal"} ClassParams={"w-100   mt-5  h-auto"} className="col-6" imageParams={"w-100"}  images={[prodData.primaryImage,...prodData.extraImages]}></Carousel>:
    <Carousel initFunction={initSwiper} id="Carousel" carouselId="#Carousel"    initialSlide={0} bootstrap={bootstrap} height={600} modal={"#CarouselModal"} ClassParams={"w-100   mt-5  h-auto"} className="col-6"  imageParams={"w-100"} images={[prodData.primaryImage]}></Carousel>)
   }
    </div>
   <div className="mt-5 p-4 col-xl-6" align="start" style={{fontSize:40,fontWeight:500,color:"#555555",backgroundColor:"#e9eaec",borderRadius:"25px"}}>
    <div>
      
    </div>
    {productName}
   <hr></hr>
   <div style={{fontSize:22}}>{dictionary.purchase_info}</div>
   {dictionary.purchase_info?<hr></hr>:""}
   {prodData["additional_description"+lang.toUpperCase()]?<div style={{fontSize:22}}>{parse(prodData["additional_description"+lang.toUpperCase()])}</div>:<></>}
   {prodData["additional_description"+lang.toUpperCase()]?<hr></hr>:<></>}
   <div style={{color:"#4e8735"}} align="start">{prodData.price}€</div>
   </div>
   
    </div>
    </div>
    
    <hr></hr>
    <div className="mt-4 mx-2 mx-md-5 mb-5">
    <div  className="mb-4"  style={{fontSize:25,fontWeight:500,color:"#555555"}}>{dictionary.description}</div>
    <div align="start" id="description">{parse(prodData["description"+lang.toUpperCase()])}</div>
    </div>
    
    <div className='mx-md-5 mx-4 mb-5 py-5'>
  
  {category.product.length>1?<div className='mb-5' key={category.name}>
  <div style={{fontWeight:"bold",color:"#4e8735",fontSize:20}} align="start" className='mb-3 ms-2 mt-5'>{dictionary.other_products}</div>
  <hr></hr>
  <div className='d-flex flex-wrap row'>
  {category.product.map(prod=> {
  if(prod.name!=prodData.name){
    return <ProductItem key={prod.name} product={prod} lang={lang}></ProductItem>
  }
  
  })}
    
  


  </div>
  </div>:<></>}
 
  </div></div>
          :<div>
            <div className='mt-5' style={{fontSize:40,fontWeight:500,color:"#4e8735"}}>tāda produkta nav</div>
            </div>}
          
         
          
        </main>
      )
    }