import data from '../products.json'
import  Carousel  from './components/Carousel';
import banneris1 from "./../assets/Carl Gustaf banneris_jauns_2.jpg"
import banneris2 from "./../assets/Carl Gustaf banneris 2.jpg"
import { ProductItem } from './components/ProductItem';
import parse from 'html-react-parser'
import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
export default function Main({lang}) {
  

  const [dictionary,setDictionary] = useOutletContext();
  return (
    <div className="my-container mb-5 pb-5">
      <Helmet htmlAttributes={{ lang : lang }}>
          <meta charSet="utf-8" />
          <title>CG84</title>
          <meta name="description" content={"Somas un siksnas civilām un militārām vajadzībām. CG84"}/>
      </Helmet>
      <div>
        <div className='mx-4'>
        <Carousel images={[banneris1,banneris2]} carousel={"bootstrap"} ClassParams = {"w-100 mx-auto justify-content-center"} bttnColor={"-white"} imageParams={"w-100"}></Carousel>
        </div>
      
       <div className='mx-4 mb-5'>
       {data.map(category=> {
        
        
        return<div className='mb-5' key={category.name}>
          <div style={{fontWeight:"bold",color:"#4e8735",fontSize:20}} align="start" className='mb-3 ms-2 mt-5'>{lang=="lv"?category.nameLV:lang=="et"?category.nameET:lang=="lt"?category.nameLT:lang=="ee"?category.nameET:lang=="sv"?category.nameSV:lang=="fi"?category.nameFI:category.name}</div>
          <hr></hr>
          <div className='d-flex flex-wrap row'>
            
          {category.product.map(product=>{
          return (<ProductItem key={product.name} product={product} lang={lang} ></ProductItem>)
        })}
          </div>
        </div>
        
      
      })}
        </div> 
      
        <div className="container mt-5 pt-5">
    <div className="row h-100" style={{border:"1px solid #ebecee"}}>
        <div className="working-time-8 col-md-8">
            <div className='h-100'>
                <iframe style={{border:0,width:"100%",height:"100%"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2176.0297184060078!2d24.11638361554155!3d56.948292106622944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eecfd37a9d67c7%3A0xc5316fead4c015d9!2zUmFpxYZhIGJ1bHbEgXJpcyAzMSwgQ2VudHJhIHJham9ucywgUsSrZ2EsIExWLTEwNTA!5e0!3m2!1slv!2slv!4v1580536396863!5m2!1slv!2slv"  frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
            </div>
        </div>
        <div className="col-md-4">
        {parse(dictionary.working_time_big)}
        </div>
       
    </div>
</div>
      </div>
      
    </div>
  );
  }