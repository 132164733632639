
import styles from "../../ProductItem.module.css"


export const ProductItem = ({product,lang})=>{
    
    let productName = lang=="lv"?product.nameLV:lang=="et"?product.nameET:lang=="lt"?product.nameLT:lang=="sv"?product.nameSV:lang=="fi"?product.nameFI:product.name
    return <div className={`${5>4?styles['col-xl-15']:""}  align-content-center col-lg-3 col-md-4 col-sm-6 col-6 p-0 mb-2`} >
          <div className="card me-2 h-100" style={{borderRadius:"25px"}}>
          <div className={`p-1 pt-4`}> 
            <div>
            <div>
            <div className='p-0 m-0 w-100 h-100 d-flex justify-content-center'>
              <a href={"/"+lang+"/product/"+ product.seoParam} >
              <img  src={"/light"+product.primaryImage} className="w-100" style={{objectFit:"scale-down"}} width={200} height={200} ></img>
              </a>
              </div>
              </div>
              </div>
              <div className='px-2'>
            <div className='text-left ms-2 mt-2' align="start" style={{fontSize:15,color:' #787878',fontWeight:600,fontFamily:'sans-serif'}}>{productName}</div>
            
            
            
            </div>
           
            
            </div>
            <div className="mt-auto pb-4 pt-auto px-1">
            <div className='d-flex align-items-center '>
            <div className='ms-2  px-2' style={{textDecorationThickness:'1px',fontWeight:500,color:"#4e8735"}}>{product.price.toFixed(2)}€</div>
            </div>
            </div>
          </div>
           
           
            </div>
};

